import { Component, componentService, services } from '@contorion/core';
import { PRODUCTS } from '../products/constants/products.constants';

componentService.register('.js-product-filters', (element: Element) => {
    import (/* webpackChunkName: "product-filters" */
        './components/product-filters.component')
        .then(({ProductFiltersComponent}) => {
            <Component> ProductFiltersComponent.loadByElement(element, '.js-product-filters');
        });
});

componentService.register('.js-category-tree', (element: Element) => {
    import (/* webpackChunkName: "js-category-tree" */
        './components/category-tree.component')
        .then(({CategoryTreeComponent}) => {
            <Component> CategoryTreeComponent.loadByElement(element, '.js-category-tree');
        });
});

componentService.register('.js-filter-search-title', (element: Element) => {
    import (/* webpackChunkName: "js-filter-search-title" */
        './components/filter-search-title.component')
        .then(({FilterSearchTitleComponent}) => {
            <Component> FilterSearchTitleComponent.loadByElement(
                element,
                '.js-filter-search-title'
            );
        });
});

componentService.register('.js-filter-buttons', (element: Element) => {
    import (/* webpackChunkName: "js-filter-buttons" */
        './components/filter-buttons.component')
        .then(({FilterButtonsComponent}) => {
            <Component> FilterButtonsComponent.loadByElement(
                element,
                '.js-filter-buttons'
            );
        });
});

componentService.register('.js-filters-products', (element: Element) => {
    import (/* webpackChunkName: "js-filters-products" */
        './components/products.component')
        .then(({ProductsComponent}) => {
            <Component> ProductsComponent.loadByElement(element, '.js-filters-products');
        });
});

componentService.register('.js-filters-breadcrumbs', (element: Element) => {
    import (/* webpackChunkName: "js-filters-breadcrumbs" */
        './components/breadcrumbs.component')
        .then(({BreadcrumbsComponent}) => {
            <Component> BreadcrumbsComponent.loadByElement(element, '.js-filters-breadcrumbs');
        });
});

componentService.register('.js-filter-checkbox', (element: Element) => {
    import (/* webpackChunkName: "filter-checkbox"*/
        './components/filter-checkbox.component')
        .then(({FilterCheckboxComponent}) => {
            <Component> FilterCheckboxComponent.loadByElement(element);
        });
});

componentService.register('.js-filter-range', (element: Element) => {
    import (/* webpackChunkName: "filter-range"*/
        './components/filter-range.component')
        .then(({FilterRangeComponent}) => {
            <Component> FilterRangeComponent.loadByElement(element);
        });
});

componentService.register('.js-active-filters', (element: Element) => {
    import (/* webpackChunkName: "active-filters" */
        './components/active-filters.component')
        .then(({ActiveFiltersComponent}) => {
            <Component> ActiveFiltersComponent.loadByElement(element, '.js-active-filters');
        });
});

componentService.register('.js-product-sorting-dropdown', (element: Element) => {
    import (/* webpackChunkName: "product-sorting-dropdown" */
        './components/products-sorting-dropdown.component')
        .then(({ProductsSortingDropdownComponent}) => {
            <Component> ProductsSortingDropdownComponent.loadByElement(element);
        });
});

componentService.register('.js-product-sorting-select', (element: Element) => {
    import (/* webpackChunkName: "product-sorting-select" */
        './components/products-sorting-select.component')
        .then(({ProductsSortingSelectComponent}) => {
            <Component> ProductsSortingSelectComponent.loadByElement(element);
        });
});

componentService.register('.js-toggle-filters', (element: Element) => {
    import (/* webpackChunkName: "toggle-filters" */
        './components/toggle-filters.component')
        .then(({ToggleFiltersComponent}) => {
            <Component> ToggleFiltersComponent.loadByElement(element);
        });
});

componentService.register('.js-reset-filters', (element: Element) => {
    import (/* webpackChunkName: "reset-filters" */
        './components/reset-filters.component')
        .then(({ResetFiltersComponent}) => {
            <Component> ResetFiltersComponent.loadByElement(element);
        });
});

componentService.register('.js-seo-category-name', (element: Element) => {
    import (/* webpackChunkName: "seo-category-name" */
        './components/seo-category-name.component')
        .then(({SeoCategoryNameComponent}) => {
            <Component> SeoCategoryNameComponent.loadByElement(element, '.js-seo-category-name');
        });
});

componentService.register('.js-product-loading-indicator', (element: Element) => {
    import (
        /* webpackChunkName: "product-loading-indicator" */
        './components/product-loading-indicator.component')
        .then(({ ProductLoadingIndicatorComponent }) => {
            <Component> ProductLoadingIndicatorComponent.loadByElement(element);
        });
});

componentService.register('.js-sponsored-label', (element: Element) => {
    import (
        /* webpackChunkName: "sponsored-label" */
        './components/sponsored-label.component')
        .then(({ SponsoredLabelComponent }) => {
            <Component> SponsoredLabelComponent.loadByElement(element);
        });
});

services.register('filters-service', () => {
    return import (/* webpackChunkName: "filters-service"*/
        './services/filters.service')
        .then(({filtersService}) => {
            return filtersService;
        });
});
