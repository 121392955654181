import { Component, componentService } from '@contorion/core';

componentService.register('.js-ga4-view-item-event', (element: Element) => {
    import (
        /* webpackChunkName: "ga4-view-item-event" */
        './components/Ga4ViewItemEvent.component')
        .then(({ Ga4ViewItemEvent }) => {
            <Component> Ga4ViewItemEvent.loadByElement(element);
        });
});
