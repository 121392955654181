const VCT_REMOVAL_STEP1: RegExp = /^(.+?)(\?|&)vct=[a-zäöüß%,0-9\-_]+$/i;
const VCT_REMOVAL_STEP2: RegExp = /^(.+?)(\?|&)vct=[a-zäöüß%,0-9\-_]+&?(.*)$/i;

const SPONSORED_PRODUCT_REMOVAL_STEP1: RegExp = /^(.+?)(\?|&)sp=[a-zäöüß%,0-9\-_]+$/i;
const SPONSORED_PRODUCT_REMOVAL_STEP2: RegExp = /^(.+?)(\?|&)sp=[a-zäöüß%,0-9\-_]+&?(.*)$/i;

class TrackingService {
    public removeTrackingFromUrl(url: string): string {
        url = url.replace(VCT_REMOVAL_STEP1, '$1');
        url = url.replace(VCT_REMOVAL_STEP2, '$1$2$3');

        url = url.replace(SPONSORED_PRODUCT_REMOVAL_STEP1, '$1');
        url = url.replace(SPONSORED_PRODUCT_REMOVAL_STEP2, '$1$2$3');

        return url;
    }

    public removeFromHistory(url: string = window.location.href): void {
        if (!window.history || !window.history.replaceState) {
            return;
        }

        window.history.replaceState(
            null,
            null,
            this.removeTrackingFromUrl(url)
        );
    }
}

export const trackingService = new TrackingService();
