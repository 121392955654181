const COOKIE_NAME_CUSTOMER_TYPE: string = 'customerThemeType';
const COOKIE_VALUE_B2B_CUSTOMER: string = 'b2b';
const COOKIE_VALUE_B2C_CUSTOMER: string = 'b2c';
const COOKIE_CUSTOMER_TYPE_PATH: string = '/';
const COOKIE_CUSTOMER_TYPE_EXPIRY: number = 2 * 365 * 24 * 60 * 60 * 1000;

export const THEME_COOKIE = {
    b2bValue: COOKIE_VALUE_B2B_CUSTOMER,
    b2cValue: COOKIE_VALUE_B2C_CUSTOMER,
    expiry: COOKIE_CUSTOMER_TYPE_EXPIRY,
    name: COOKIE_NAME_CUSTOMER_TYPE,
    path: COOKIE_CUSTOMER_TYPE_PATH,
};
