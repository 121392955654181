import { EnvironmentComponent } from '../components/environement.component';
import { cookies } from "../helpers/cookies";
import thirdPartyErrorMessages from "./constants/third-party-errors-list.constants";
import { consentService } from "./consent.service";

const STORAGE_KEY: string = 'settings';
const PAYMENT_METHOD_KEY: string = 'paymentMethod';
let lastError: any = null;

export class LoggerService {
    protected get isDebug(): boolean {
        return !EnvironmentComponent.isProduction();
    }

    protected setCustomConsentProps() {
        window.newrelic?.setCustomAttribute(
            'hasAnalyticsConsent',
            consentService.isAnalyticsGranted(),
            true
        );
    }


    public async configNewrelic() {
        this.setCustomConsentProps();
        window.tC?.cact['consent.onUpdate']?.(() => {
            this.setCustomConsentProps();
        });

        // reduce duplicate errors sent to newrelic
        window.newrelic?.setErrorHandler((err: any) => {
            if (err === lastError) {
                return true;
            }
            lastError = err;
            if (this.isThirdPartyError(err)) {
                return { group: 'ThirdParty' };
            }

            return false;
        });

        window.newrelic?.setCustomAttribute(
            'customerThemeType',
            cookies.get("customerThemeType") ?? "",
            true
        );

        window.newrelic?.setCustomAttribute(
            'networkType',
            // some browsers are not support this yet
            navigator['connection']?.effectiveType ?? "",
            true
        );

        // add Bot Detection
        const { load } = await import('@fingerprintjs/botd');
        const botd = await load();
        const botdRes = botd.detect();
        window.newrelic?.setCustomAttribute(
            'isBot',
            `${botdRes.bot}`,
            true
        );

        //add payment Method logger
        this.updatePaymentMethodAttribute();
    }

    private updatePaymentMethodAttribute () {
        // retrieve and set the latest payment method from local storage
        const paymentMethod = JSON.parse(window.localStorage.getItem(STORAGE_KEY) || '{}').PAYMENT_METHOD_KEY;
        window.newrelic?.setCustomAttribute(PAYMENT_METHOD_KEY, paymentMethod ?? "" , true);
    }

    private isThirdPartyError(err: any) {
        if (err.message && thirdPartyErrorMessages.some(keyword => err.message.includes(keyword))) {
            return true;
        }
        return false;
    }

    public info(message: string) {
        const error = Error(message).stack;
        console.info(error);
    }

    public error(error: any, additionalMessage?: string): void {
        this.updatePaymentMethodAttribute();
        if (this.isDebug) {
            console.error(error, additionalMessage);
        }

        if (additionalMessage) {
            window.newrelic?.noticeError(error, { customMessage: additionalMessage });

            return;
        }

        window.newrelic?.noticeError(error);
    }
}

export const loggerService = new LoggerService();
