import { EnvironmentComponent } from "@contorion/core";

// dynamically change assets domain based on environment, BOSS-33902 start
__webpack_public_path__ = '/assets/';
if (EnvironmentComponent.isProduction() || EnvironmentComponent.isStaging() || EnvironmentComponent.isQuality()) {
    __webpack_public_path__ = `${window.location.protocol}//${window.BACKEND_STATIC_ASSETS_URL}/assets/`;
}
// BOSS-33902 end

import polyfills from '@contorion/polyfills';
import app from './app';

Promise.all(polyfills).then(app);
